import { Card, Col, Container, Row } from "react-bootstrap";
import MainBar from "../components/MainBar";
import { useAuth0 } from "@auth0/auth0-react";

export default function Profile() {
  const { user, isAuthenticated } = useAuth0();

  return (
    <>
      <MainBar />
      {isAuthenticated &&
        <Card>
          <Card.Body>
            <Container>
              <Row className="align-items-center profile-header mb-5 text-center text-md-left">
                <Col md={2}>
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                  />
                </Col>
                <Col md>
                  <h2>{user.name}</h2>
                  <p className="lead text-muted">{user.email}</p>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      }
    </>
  )
}

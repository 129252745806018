import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ModalBox from "./ModalBox";
import { Form, Stack, Table } from "react-bootstrap";
import MsgBox from "./MsgBox";
import Btn from "./Btn";
import axios from "axios";

export default function BrowseCustomer(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [findText, setFindText] = useState('');
  const [isErr, setIsErr] = useState(false);
  const [msg, setMsg] = useState('');
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setMsg('');
    setIsErr(false);
    startLoading();
    try {
      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      let url = `${process.env.REACT_APP_API_URL}/rest/customers`;

      if (findText) {
        url = `${process.env.REACT_APP_API_URL}/rest/customers?q={"$or": [{"nama": {"$regex": "${findText}"}}, {"keterangan": {"$regex": "${findText}"}}]}`;
      }

      const posts = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
      setData(posts.data);
    } catch(error) {
      setIsErr(true);
      if (error.response) {
        setMsg(error.response.data.errors);
      } else {
        setMsg(error.message);
      }
    }
    stopLoading();
  }

  const selectData = (post) => {
    props.selectData(post);
  }

  const closeMsgBox = () => {
    setMsg('');
    setIsErr(false);
  }

  const content = (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Nama</th>
          <th>Keterangan</th>
        </tr>
      </thead>
      <tbody>
        {data.map(post => {
          return (
            <tr key={post._id} onClick={() => selectData(post)}>
              <td>{post.nama}</td>
              <td>{post.keterangan}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );

  return (
    <ModalBox show={props.show} onHide={props.onHide} title='Customer'>
      <Stack gap={2}>
        <MsgBox msg={msg} isErr={isErr} onClickClose={() => closeMsgBox()}/>
        <Form.Group>
          <Form.Control type="text"
            placeholder="Isi di sini untuk mencari Customer ...."
            onChange={(e) => setFindText(e.target.value)} />
        </Form.Group>
        <Btn variant="primary" size="lg" onClick={() => getData()} processing={isLoading}>Cari</Btn>
        {content}
      </Stack>
    </ModalBox>
  )
}

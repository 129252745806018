import { Button,Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Btn = (props) => (
  <Button variant={props.variant} size={props.size} onClick={props.onClick} disabled={props.disabled || props.processing}>
    {props.processing && (
      <>
      <Spinner
        as="span"
        animation="border"
        size="sm"
      /> Loading...
      </>
    )}
    {props.icon && !props.processing &&
      <>
        <FontAwesomeIcon icon={props.icon} className="mr-2" />&nbsp;
      </>
    }
    {!props.processing && props.children}
  </Button>
);

export default Btn;

import { useEffect, useState } from 'react';
import { Card, Col, Form, InputGroup, Pagination, Placeholder, Row, Stack } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useNavigate
} from 'react-router-dom';
import axios from 'axios';
import Btn from '../components/Btn';
import MainWrapper from '../components/MainWrapper';

export default function Supplier() {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading , setIsLoading] = useState(false);
  const [suppliers, setSuppliers] = useState(null);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState('');
  const limit = 10;
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      setIsLoading(true);

      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/rest/suppliers?q={}&max=${limit}&filter=${filter}&skip=${page}&totals=true`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.data) {
        setSuppliers(response.data.data);
        setMeta(response.data.totals);
      }

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
    }
  };

  useEffect(() => {
    refresh();
  }, [page]);

  const gotoFirst = () => {
    if (!isLoading) {
      setPage(0);
    }
  };

  const gotoPrev = () => {
    if (!isLoading && page > 0) {
      setPage(page - 1);
    }
  }

  const gotoNext = () => {
    if (!isLoading && meta) {
      if (Math.ceil(meta?.total / limit) > page + 1) {
        setPage(page + 1);
      }
    }
  }

  const gotoEnd = () => {
    if (!isLoading && meta) {
      setPage(Math.ceil(meta?.total / limit) - 1);
    }
  }

  const tambahData = () => {
    navigate('/supplier/detail');
  }

  const dataDetail = (detail) => {
    navigate('/supplier/detail', { state: { supplier: detail } });
  }

  return (
    <MainWrapper>
      <Stack gap={2}>
        <Row>
          <Col sm={8}>
            <InputGroup>
              <Form.Control
                placeholder="Filter Text"
                aria-label="Filter Text"
                aria-describedby="basic-addon2"
                value={filter}
                onChange={(e) => setFilter(e.currentTarget.value)}
              />
              <Btn variant="outline-secondary" icon="filter" onClick={() => refresh()}
                processing={isLoading}>
                Filter
              </Btn>
              <Btn variant="outline-secondary" icon="cancel" onClick={async () => {
                await setFilter('');
                await refresh();
              }} processing={isLoading}>
                Reset
              </Btn>
            </InputGroup>
          </Col>
          <Col sm={4}>
            <Btn variant="primary" icon="add" onClick={() => tambahData()} processing={isLoading}>
              Create
            </Btn>
          </Col>
        </Row>
        {suppliers && !isLoading ?
          suppliers.map((supplier, index) => (
            <Card key={index} onClick={() => dataDetail(supplier)} style={{ cursor: 'pointer'}}>
              <Card.Body>
                <Card.Title>{supplier.nama}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{supplier.keterangan}</Card.Subtitle>
              </Card.Body>
            </Card>
          )) :
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        }
        <Pagination>
          <Pagination.First onClick={gotoFirst} />
          <Pagination.Prev onClick={gotoPrev} />
          <Pagination.Item>{page + 1} of {Math.ceil(meta?.total / limit)}</Pagination.Item>
          <Pagination.Next onClick={gotoNext} />
          <Pagination.Last onClick={gotoEnd} />
        </Pagination>
      </Stack>
    </MainWrapper>
  )
}

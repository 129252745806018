import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import Main from './routes/main';
import Profile from './routes/profile';
import Supplier from './routes/supplier';
import SupplierDetail from './routes/supplierdetail';
import Customer from './routes/customer';
import CustomerDetail from './routes/customerdetail';
import Product from './routes/product';
import ProductDetail from './routes/productdetail';
import Pembelian from './routes/pembelian';
import PembelianDetail from './routes/pembeliandetail';
import Penjualan from './routes/penjualan';
import PenjualanDetail from './routes/penjualandetail';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/supplier",
    element: <Supplier />,
  },
  {
    path: "/supplier/detail",
    element: <SupplierDetail />,
  },
  {
    path: "/customer",
    element: <Customer />,
  },
  {
    path: "/customer/detail",
    element: <CustomerDetail />,
  },
  {
    path: "/product",
    element: <Product />,
  },
  {
    path: "/product/detail",
    element: <ProductDetail />,
  },
  {
    path: "/pembelian",
    element: <Pembelian />,
  },
  {
    path: "/pembelian/detail",
    element: <PembelianDetail />,
  },
  {
    path: "/penjualan",
    element: <Penjualan />,
  },
  {
    path: "/penjualan/detail",
    element: <PenjualanDetail />,
  },
]);

const App = () => <RouterProvider router={router} />;

export default App;

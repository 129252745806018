import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { versi } from '../common/setting';

function MainBar() {
  const navigate = useNavigate();
  const { loginWithRedirect, user, logout, isAuthenticated } = useAuth0();

  const gotoHome = () => {
    navigate('/');
  }

  const gotoProfile = () => {
    navigate('/profile');
  }

  const gotoSupplier = () => {
    navigate('/supplier');
  }

  const gotoCustomer = () => {
    navigate('/customer');
  }

  const gotoProduct = () => {
    navigate('/product');
  }

  const gotoPembelian = () => {
    navigate('/pembelian');
  }

  const gotoPenjualan = () => {
    navigate('/penjualan');
  }

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand>Stock {versi}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={gotoHome}>Home</Nav.Link>
            {isAuthenticated &&
              <>
                <NavDropdown title="Master" id="collapsible-nav-dropdown">
                  <NavDropdown.Item onClick={gotoSupplier}>Supplier</NavDropdown.Item>
                  <NavDropdown.Item onClick={gotoCustomer}>Customer</NavDropdown.Item>
                  <NavDropdown.Item onClick={gotoProduct}>
                    Product
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Transaksi" id="collapsible-nav-dropdown">
                  <NavDropdown.Item onClick={gotoPembelian}>Pembelian</NavDropdown.Item>
                  <NavDropdown.Item onClick={gotoPenjualan}>Penjualan</NavDropdown.Item>
                </NavDropdown>
              </>
            }
          </Nav>
          <Nav>
            {!isAuthenticated &&
            <Nav.Link onClick={() => loginWithRedirect()}>Login</Nav.Link>}
            {isAuthenticated &&
              <NavDropdown title={user.name} id="collapsible-nav-dropdown">
                <Nav.Link onClick={gotoProfile}>Profile</Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link onClick={() => logout()}>
                  <FontAwesomeIcon icon="power-off" className="mr-2" /> Logout
                </Nav.Link>
              </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainBar;

import { useEffect, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Form, Stack } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Btn from '../components/Btn';
import MainWrapper from '../components/MainWrapper';

export default function CustomerDetail() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  let location = useLocation();
  const [isLoading , setIsLoading] = useState(false);
  const [customer, setCustomer] = useState({
    _id: '',
    nama: '',
    keterangan: ''
  });

  useEffect(() => {
    if (location.state) {
      if (location.state.customer) {
        setCustomer({...location.state.customer});
      }
    }
  }, [location]);

  const simpan = async () => {
    try {
      setIsLoading(true);

      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      const config = {
        url: `${process.env.REACT_APP_API_URL}/rest/customers/${customer._id}`,
        method: 'put',
      }

      if (!location.state) {
        config.method = 'post';
        config.url = `${process.env.REACT_APP_API_URL}/rest/customers`;
      }

      const response = await axios({
        method: config.method,
        url: config.url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          nama: customer.nama,
          keterangan: customer.keterangan
        }
      });

      if (response) {
        navigate(-1);
      }

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
    }
  }

  const hapus = async () => {
    try {
      setIsLoading(true);

      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      const config = {
        url: `${process.env.REACT_APP_API_URL}/rest/customers/${customer._id}`,
        method: 'DELETE',
      }

      const response = await axios({
        method: config.method,
        url: config.url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });

      if (response) {
        navigate(-1);
      }

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
    }
  }

  const cancel = () => {
    navigate(-1);
  }

  return (
    <MainWrapper>
      <Stack gap={2}>
        <Form.Label htmlFor="nama">Nama</Form.Label>
        <Form.Control
          type="text"
          id="nama"
          aria-describedby="nama"
          value={customer.nama}
          onChange={(e) => setCustomer({...customer, nama: e.target.value})}
        />
        <Form.Label htmlFor="keterangan">Keterangan</Form.Label>
        <Form.Control
          type="text"
          id="keterangan"
          aria-describedby="keterangan"
          value={customer.keterangan}
          onChange={(e) => setCustomer({...customer, keterangan: e.target.value})}
        />
        <Stack gap={3} direction='horizontal'>
          <Btn variant="primary" onClick={simpan} processing={isLoading}>Save</Btn>
          <Btn variant="outline-warning" onClick={cancel} processing={isLoading}>Cancel</Btn>
          <Btn variant="danger" onClick={hapus} processing={isLoading}>Hapus</Btn>
        </Stack>
      </Stack>
    </MainWrapper>
  )
}

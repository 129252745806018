import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAdd,
  faFilter,
  faCancel,
  faPowerOff,
  faUser,
  faBackwardFast,
  faBackwardStep,
  faForwardStep,
  faForwardFast,
} from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faAdd);
  library.add(faFilter);
  library.add(faCancel);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faBackwardFast);
  library.add(faBackwardStep);
  library.add(faForwardStep);
  library.add(faForwardFast);
}

export default initFontAwesome;

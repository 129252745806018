import { useEffect, useState } from "react";
import MainWrapper from "../components/MainWrapper";
import MsgBox from "../components/MsgBox";
import { Form, Stack } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-US";
import BrowseInput from "../components/BrowseInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import NumericInput from "../components/NumericInput";
import Btn from "../components/Btn";
import BrowseCustomer from "../components/BrowseCustomer";
import BrowseProduct from "../components/BrowseProduct";

export default function PenjualanDetail() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  let location = useLocation();
  const [isErr, setIsErr] = useState(false);
  const [msg, setMsg] = useState('');
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const [sales, setSales] = useState({
    _id: '',
    tanggal: new Date(),
    customer: {
      _id: '',
      nama: '',
      keterangan: ''
    },
    product: {
      _id: '',
      nama: '',
      keterangan: ''
    },
    qty: 0,
    harga: 0,
    keterangan: ''
  });

  useEffect(() => {
    registerLocale("en", en);
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.sales) {
        console.log(location.state.sales);
        setSales({...location.state.sales});
      }
    } else {
      setSales({...sales, tanggal: new Date()});
    }
  }, [location]);

  const showBrowseCustomer = () => {
    setShowCustomerModal(true);
  }

  const showBrowseProduct = () => {
    setShowProductModal(true);
  }

  const validasi = async () => {
    if (!sales.qty || sales.qty <= 0) {
      setMsg('Harap isi Qty');
      setIsErr(true);
      return false;
    }

    if (!sales.harga || sales.harga <= 0) {
      setMsg('Harap isi Harga');
      setIsErr(true);
      return false;
    }

    if (!sales.customer._id) {
      setMsg('Harap pilih customer');
      setIsErr(true);
      return false;
    }

    if (!sales.tanggal) {
      setMsg('Harap pilih Tanggal');
      setIsErr(true);
      return false;
    }

    const authToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE,
      },
    });

    const stokConfig = {
      url: `${process.env.REACT_APP_API_URL}/rest/products/${sales.product._id}`,
      method: 'put',
    }

    const resCurrentQtyStok = await axios({
      method: 'get',
      url: stokConfig.url,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (resCurrentQtyStok) {
      if (resCurrentQtyStok.data.qty < sales.qty) {
        setMsg('Stok tidak mencukupi');
        setIsErr(true);
        return false;
      }
    }

    return true;
  }

  const simpan = async () => {
    if (!(await validasi())) return;

    try {
      setIsLoading(true);

      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      const config = {
        url: `${process.env.REACT_APP_API_URL}/rest/sales/${sales._id}`,
        method: 'put',
      }

      const stokConfig = {
        url: `${process.env.REACT_APP_API_URL}/rest/products/${sales.product._id}`,
        method: 'put',
      }

      if (!location.state) {
        config.method = 'post';
        config.url = `${process.env.REACT_APP_API_URL}/rest/sales`;
      }

      const resCurrentQtyStok = await axios({
        method: 'get',
        url: stokConfig.url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (resCurrentQtyStok) {
        let curQty = resCurrentQtyStok.data.qty || 0;

        const reqJual = axios({
          method: config.method,
          url: config.url,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            tanggal: sales.tanggal,
            customer: sales.customer,
            product: sales.product,
            qty: sales.qty,
            harga: sales.harga,
            keterangan: sales.keterangan
          }
        });

        const reqUpdateStok = axios({
          method: stokConfig.method,
          url: stokConfig.url,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            qty: parseInt(curQty) - parseInt(sales.qty)
          }
        });

        const response = await axios.all([
          reqJual,
          reqUpdateStok
        ]);

        if (response) {
          navigate(-1);
        }
      }

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
    }
  }

  const hapus = async () => {
    try {
      setIsLoading(true);

      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      const stokConfig = {
        url: `${process.env.REACT_APP_API_URL}/rest/products/${sales.product._id}`,
        method: 'put',
      }

      const config = {
        url: `${process.env.REACT_APP_API_URL}/rest/sales/${sales._id}`,
        method: 'DELETE',
      }

      const resCurrentQtyStok = await axios({
        method: 'get',
        url: stokConfig.url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (resCurrentQtyStok) {
        let curQty = resCurrentQtyStok.data.qty || 0;

        const reqDelete = axios({
          method: config.method,
          url: config.url,
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });

        const reqUpdateStok = axios({
          method: stokConfig.method,
          url: stokConfig.url,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            qty: parseInt(curQty) + parseInt(sales.qty)
          }
        });

        const response = await axios.all([
          reqDelete,
          reqUpdateStok
        ]);

        if (response) {
          navigate(-1);
        }
      }

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
    }
  }

  const cancel = () => {
    navigate(-1);
  }

  const selectCustomer = (customer) => {
    setSales({...sales, customer});
    setShowCustomerModal(false);
  }

  const selectProduct = (product) => {
    setSales({...sales, product});
    setShowProductModal(false);
  }

  const closeMsgBox = () => {
    setMsg('');
    setIsErr(false);
  }

  return (
    <MainWrapper>
      <MsgBox msg={msg} isErr={isErr} onClickClose={() => closeMsgBox()}/>
      <Stack gap={2}>
        <Form.Label htmlFor="tanggal">Tanggal</Form.Label>
        <DatePicker placeholderText='Tanggal' locale={en} dateFormat='dd-MMM-yyyy' selected={sales.tanggal} onChange={(date) => setSales({...sales, tanggal: date})} className='form-control'/>
        <BrowseInput caption='Customer' value={sales.customer?.nama}
          onClick={() => showBrowseCustomer()}
          loading={isLoading} onClickClear={() => setSales({...sales, customer: {
            _id: '',
            nama: '',
            keterangan: ''
          }
        })} />
        <BrowseInput caption='Produk' value={sales.product?.nama}
          onClick={() => showBrowseProduct()}
          loading={isLoading} onClickClear={() => setSales({...sales, product: {
            _id: '',
            nama: '',
            keterangan: ''
          }
        })} />
        <NumericInput caption='Qty' value={sales.qty} decimalPlaces='0' onChange={(event, value) => setSales({...sales, qty: value})} loading={isLoading}/>
        <NumericInput caption='Harga' value={sales.harga} decimalPlaces='0' onChange={(event, value) => setSales({...sales, harga: value})} loading={isLoading}/>
        <Form.Label htmlFor="keterangan">Keterangan</Form.Label>
        <Form.Control
          type="text"
          id="keterangan"
          aria-describedby="keterangan"
          value={sales.keterangan}
          onChange={(e) => setSales({...sales, keterangan: e.target.value})}
        />
        <Stack gap={3} direction='horizontal'>
          <Btn variant="primary" onClick={simpan} processing={isLoading}>Save</Btn>
          <Btn variant="outline-warning" onClick={cancel} processing={isLoading}>Cancel</Btn>
          <Btn variant="danger" onClick={hapus} processing={isLoading}>Hapus</Btn>
        </Stack>
      </Stack>
      <BrowseCustomer show={showCustomerModal} onHide={() => setShowCustomerModal(false)} selectData={selectCustomer} />
      <BrowseProduct show={showProductModal} onHide={() => setShowProductModal(false)} selectData={selectProduct} />
    </MainWrapper>
  )
}

import { Card, Col, Form, InputGroup, Pagination, Placeholder, Row, Stack } from "react-bootstrap";
import MainWrapper from "../components/MainWrapper";
import Btn from "../components/Btn";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { FormatAngka } from "../helpers/Formatter";

export default function Penjualan() {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading , setIsLoading] = useState(false);
  const [sales, setSales] = useState(null);
  const [meta, setMeta] = useState(null);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [page, setPage] = useState(0);
  const limit = 10;
  const navigate = useNavigate();

  const refresh = async () => {
    try {
      setIsLoading(true);

      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      let url = `${process.env.REACT_APP_API_URL}/rest/sales?q={}&max=${limit}&filter=${filter}&skip=${page}&totals=true`;

      if (sort) {
        switch (sort) {
          case 'tanggal_asc':
            url = `${process.env.REACT_APP_API_URL}/rest/sales?q={}&max=${limit}&filter=${filter}&skip=${page}&totals=true&h={"$orderby": {"tanggal": 1}}`;
            break;
          case 'tanggal_desc':
            url = `${process.env.REACT_APP_API_URL}/rest/sales?q={}&max=${limit}&filter=${filter}&skip=${page}&totals=true&h={"$orderby": {"tanggal": -1}}`;
            break;
          default:
            break;
        }
      }

      const response = await axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.data) {
        setSales(response.data.data);
        setMeta(response.data.totals);
      }

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
    }
  };

  useEffect(() => {
    refresh();
  }, [sort, page]);

  const gotoFirst = () => {
    if (!isLoading) {
      setPage(0);
    }
  };

  const gotoPrev = () => {
    if (!isLoading && page > 0) {
      setPage(page - 1);
    }
  }

  const gotoNext = () => {
    if (!isLoading && meta) {
      if (Math.ceil(meta?.total / limit) > page + 1) {
        setPage(page + 1);
      }
    }
  }

  const gotoEnd = () => {
    if (!isLoading && meta) {
      setPage(Math.ceil(meta?.total / limit) - 1);
    }
  }

  const tambahData = () => {
    navigate('/penjualan/detail');
  }

  const dataDetail = (detail) => {
    navigate('/penjualan/detail', { state: { sales: detail } });
  }

  return(
    <MainWrapper>
      <Stack gap={2}>
        <Row>
          <Col md={6}>
            <InputGroup>
              <Form.Control
                placeholder="Filter Text"
                aria-label="Filter Text"
                aria-describedby="basic-addon2"
                value={filter}
                onChange={(e) => setFilter(e.currentTarget.value)}
              />
              <Btn variant="outline-secondary" icon="filter" processing={isLoading} onClick={() => refresh()}>
                Filter
              </Btn>
              <Btn variant="outline-secondary" icon="cancel" processing={isLoading} onClick={async () => {
                await setFilter('');
                await refresh();
              }}>Reset</Btn>
            </InputGroup>
          </Col>
          <Col md={4}>
            <Form.Select aria-label="Default select example" onChange={(e) => setSort(e.currentTarget.value)}>
              <option>Sort By</option>
              <option value="tanggal_asc">Tanggal Ascending</option>
              <option value="tanggal_desc">Tanggal Descending</option>
            </Form.Select>
          </Col>
          <Col md={2}>
            <Btn variant="primary" icon="add" onClick={() => tambahData()} processing={isLoading}>Create</Btn>
          </Col>
        </Row>
        {sales && !isLoading ?
          sales.map((sale, index) => (
            <Card key={index} onClick={() => dataDetail(sale)} style={{ cursor: 'pointer'}}>
              <Card.Body>
                <Card.Title>{sale._id}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{sale.customer.nama}</Card.Subtitle>
                <Card.Text>{moment(sale.tanggal).format("DD-MMM-yyyy")}</Card.Text>
                <Card.Text>{sale.product.nama} - <b>Harga Rp. {FormatAngka(sale.harga)}</b></Card.Text>
              </Card.Body>
            </Card>
          )) :
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        }
        <Pagination>
          <Pagination.First onClick={gotoFirst} />
          <Pagination.Prev onClick={gotoPrev} />
          <Pagination.Item>{page + 1} of {Math.ceil(meta?.total / limit)}</Pagination.Item>
          <Pagination.Next onClick={gotoNext} />
          <Pagination.Last onClick={gotoEnd} />
        </Pagination>
      </Stack>
    </MainWrapper>
  )
}

import { Container } from "react-bootstrap";
import MainBar from "./MainBar";
import { useAuth0 } from "@auth0/auth0-react";

export default function MainWrapper(props) {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <MainBar />
      <Container>
        {isAuthenticated && props.children}
      </Container>
    </>
  )
}

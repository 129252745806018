import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// fontawesome
import initFontAwesome from "./helpers/initFontAwesome";
initFontAwesome();

const root = ReactDOM.createRoot(document.getElementById('root'));

const providerConfig = {
  domain: process.env.REACT_APP_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(process.env.REACT_APP_AUDIENCE ? { audience: process.env.REACT_APP_AUDIENCE } : null),
  },
};

root.render(
  <React.StrictMode>
    <Auth0Provider
      {...providerConfig}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

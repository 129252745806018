import { Form, Stack } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-US";
import BrowseInput from "../components/BrowseInput";
import BrowseSupplier from "../components/BrowseSupplier";
import BrowseProduct from "../components/BrowseProduct";
import MainWrapper from "../components/MainWrapper";
import Btn from "../components/Btn";
import NumericInput from "../components/NumericInput";
import MsgBox from "../components/MsgBox";

export default function PembelianDetail() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  let location = useLocation();
  const [isErr, setIsErr] = useState(false);
  const [msg, setMsg] = useState('');
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const [purchase, setPurchase] = useState({
    _id: '',
    tanggal: new Date(),
    supplier: {
      _id: '',
      nama: '',
      keterangan: ''
    },
    product: {
      _id: '',
      nama: '',
      keterangan: '',
    },
    qty: 0,
    harga: 0,
    keterangan: ''
  });

  useEffect(() => {
    registerLocale("en", en);
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.purchase) {
        setPurchase({...location.state.purchase});
      }
    } else {
      setPurchase({...purchase, tanggal: new Date()});
    }
  }, [location]);

  const showBrowseSupplier = () => {
    setShowSupplierModal(true);
  }

  const showBrowseProduct = () => {
    setShowProductModal(true);
  }

  const validasi = () => {
    if (!purchase.qty || purchase.qty <= 0) {
      setMsg('Harap isi Qty');
      setIsErr(true);
      return false;
    }

    if (!purchase.harga || purchase.harga <= 0) {
      setMsg('Harap isi Harga');
      setIsErr(true);
      return false;
    }

    if (!purchase.supplier._id) {
      setMsg('Harap pilih supplier');
      setIsErr(true);
      return false;
    }

    if (!purchase.tanggal) {
      setMsg('Harap pilih Tanggal');
      setIsErr(true);
      return false;
    }

    return true;
  }

  const simpan = async () => {
    if (!(await validasi())) return;

    try {
      setIsLoading(true);

      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      const config = {
        url: `${process.env.REACT_APP_API_URL}/rest/purchases/${purchase._id}`,
        method: 'put',
      }

      const stokConfig = {
        url: `${process.env.REACT_APP_API_URL}/rest/products/${purchase.product._id}`,
        method: 'put',
      }

      if (!location.state) {
        config.method = 'post';
        config.url = `${process.env.REACT_APP_API_URL}/rest/purchases`;
      }

      // const response = await axios({
      //   method: config.method,
      //   url: config.url,
      //   headers: {
      //     Authorization: `Bearer ${authToken}`,
      //   },
      //   data: {
      //     tanggal: purchase.tanggal,
      //     supplier: purchase.supplier,
      //     product: purchase.product,
      //     qty: purchase.qty,
      //     harga: purchase.harga,
      //     keterangan: purchase.keterangan
      //   }
      // });

      const resCurrentQtyStok = await axios({
        method: 'get',
        url: stokConfig.url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (resCurrentQtyStok) {
        let curQty = resCurrentQtyStok.data.qty || 0;

        const reqBeli = axios({
          method: config.method,
          url: config.url,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            tanggal: purchase.tanggal,
            supplier: purchase.supplier,
            product: purchase.product,
            qty: purchase.qty,
            harga: purchase.harga,
            keterangan: purchase.keterangan
          }
        });

        const reqUpdateStok = axios({
          method: stokConfig.method,
          url: stokConfig.url,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            qty: parseInt(curQty) + parseInt(purchase.qty)
          }
        });

        const response = await axios.all([
          reqBeli,
          reqUpdateStok
        ]);

        if (response) {
          navigate(-1);
        }
      }

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
    }
  }

  const hapus = async () => {
    try {
      setIsLoading(true);

      const authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE,
        },
      });

      const stokConfig = {
        url: `${process.env.REACT_APP_API_URL}/rest/products/${purchase.product._id}`,
        method: 'put',
      }

      const config = {
        url: `${process.env.REACT_APP_API_URL}/rest/purchases/${purchase._id}`,
        method: 'DELETE',
      }

      const resCurrentQtyStok = await axios({
        method: 'get',
        url: stokConfig.url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (resCurrentQtyStok) {
        let curQty = resCurrentQtyStok.data.qty || 0;

        if (curQty > purchase.qty) {
          setMsg('Qty Stok tidak mencukupi');
          setIsErr(true);
          return;
        }

        const reqDelete = axios({
          method: config.method,
          url: config.url,
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });

        const reqUpdateStok = axios({
          method: stokConfig.method,
          url: stokConfig.url,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            qty: parseInt(curQty) - parseInt(purchase.qty)
          }
        });

        const response = await axios.all([
          reqDelete,
          reqUpdateStok
        ]);

        if (response) {
          navigate(-1);
        }
      }

      setIsLoading(false);

    } catch (e) {
      setIsLoading(false);
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
    }
  }

  const cancel = () => {
    navigate(-1);
  }

  const selectSupplier = (supplier) => {
    setPurchase({...purchase, supplier});
    setShowSupplierModal(false);
  }

  const selectProduct = (product) => {
    setPurchase({...purchase,
      product:{
        _id: product._id,
        nama: product.nama,
        keterangan: product.keterangan
      }
    });
    setShowProductModal(false);
  }

  const closeMsgBox = () => {
    setMsg('');
    setIsErr(false);
  }

  return (
    <MainWrapper>
      <MsgBox msg={msg} isErr={isErr} onClickClose={() => closeMsgBox()}/>
      <Stack gap={2}>
        <Form.Label htmlFor="tanggal">Tanggal</Form.Label>
        <DatePicker placeholderText='Tanggal' locale={en} dateFormat='dd-MMM-yyyy' selected={purchase.tanggal} onChange={(date) => setPurchase({...purchase, tanggal: date})} className='form-control'/>
        <BrowseInput caption='Supplier' value={purchase.supplier?.nama}
          onClick={() => showBrowseSupplier()}
          loading={isLoading} onClickClear={() => setPurchase({...purchase, supplier: {
            _id: '',
            nama: '',
            keterangan: ''
          }
        })} />
        <BrowseInput caption='Produk' value={purchase.product?.nama}
          onClick={() => showBrowseProduct()}
          loading={isLoading} onClickClear={() => setPurchase({...purchase, product: {
            _id: '',
            nama: '',
            keterangan: ''
          }
        })} />
        <NumericInput caption='Qty' value={purchase.qty} decimalPlaces='0' onChange={(event, value) => setPurchase({...purchase, qty: value})} loading={isLoading}/>
        <NumericInput caption='Harga' value={purchase.harga} decimalPlaces='0' onChange={(event, value) => setPurchase({...purchase, harga: value})} loading={isLoading}/>
        <Form.Label htmlFor="keterangan">Keterangan</Form.Label>
        <Form.Control
          type="text"
          id="keterangan"
          aria-describedby="keterangan"
          value={purchase.keterangan}
          onChange={(e) => setPurchase({...purchase, keterangan: e.target.value})}
        />
        <Stack gap={3} direction='horizontal'>
          <Btn variant="primary" onClick={simpan} processing={isLoading}>Save</Btn>
          <Btn variant="outline-warning" onClick={cancel} processing={isLoading}>Cancel</Btn>
          <Btn variant="danger" onClick={hapus} processing={isLoading}>Hapus</Btn>
        </Stack>
      </Stack>
      <BrowseSupplier show={showSupplierModal} onHide={() => setShowSupplierModal(false)} selectData={selectSupplier} />
      <BrowseProduct show={showProductModal} onHide={() => setShowProductModal(false)} selectData={selectProduct} />
    </MainWrapper>
  )
}
